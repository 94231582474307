import React, {
  useCallback,
  Suspense,
  useState,
  useEffect,
  useRef,
} from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { DateFormat, ScreenLayout, SecureStore } from '@libs/utils'
import { Text } from 'react-native'
import { useIsFocused } from '@react-navigation/native'
import {
  JpegImage,
  JpgImage,
  MiscImage,
  PdfImage,
  PngImage,
} from '@apphero/assets'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@libs/theme'
import { formatIntakeDate } from '@libs/utils/src/dateformat'
import { useUploadDocument } from '@app-hero/shared-hooks'
import {
  getDocumentType,
  getDocumentsByOpportunityId,
  getOpportunitiesById,
  updateReadState,
  getActionRequiredItemsByOpportunityId,
  submitAllOpportunityTaskDocuments,
} from '../../api'
import { getRecentData, handleNavigation } from '../../utils/function'
import DesktopView from './DesktopView'
import MobileView from './MobileView'
import { categorizeActionItems } from '../../utils/categorizeActionItems'

import { getProgrammeName } from '../../utils/getProgramName'
import { useAtom } from 'jotai'
import { userProfile } from '../../utils/atom'
import { excludedDurationBrands } from '../../constants'

const ViewApplicationDetails = (props) => {
  const isFocused = useIsFocused()
  const opportunityId = props?.route?.params?.id
  const [isFileUpload, setIsFileUpload] = useState()
  const dropDownRef = useRef()
  const [dropdownTop, setDropdownTop] = useState(0)
  const [dropdownLeft, setDropdownLeft] = useState(0)
  const [dropdownWidth, setDropDownWidth] = useState(0)
  const [modalVisible, setModalVisible] = useState(false)
  const [informationList, setInformationList] = useState([])
  const [applicationDocuments, setApplicationDocuments] = useState()
  const [downloadApplicationData, setDownloadApplicationData] = useState()
  const [applicationLetterDocuments, setApplicationLetterDocuments] = useState()
  const { t, i18n } = useTranslation()
  const scrollViewRef = useRef(null)
  const childRef = useRef(null)
  const [brandLogoUrl, setBrandLogoUrl] = useState('')
  const tabIsActive = props?.route?.params?.isActive
  const canExpandCompletedAction =
    props?.route?.params?.canExpandCompletedAction
  const isActiveComment = props?.route?.params?.commentId
  const isFromDashboard = props?.route?.params?.isDashboard
  const queryClient = useQueryClient()
  const [userDetails] = useAtom(userProfile)

  useEffect(() => {
    if (!isFocused) return
    ;(async () => {
      const configData = await SecureStore.getItemAsync('config')
      const config = JSON.parse(configData)?.config
      setBrandLogoUrl(config?.brandLogoUrl)
    })()
  }, [isFocused])

  const LayoutView = useCallback(
    ScreenLayout.withLayoutView(DesktopView, MobileView, MobileView),
    [],
  )

  const infoTabData = (finalData) => {
    const duration =
      finalData?.OpportunityLineItems?.records[0]?.Product2?.Duration__c

    if (!finalData?.isDirectSales) {
      return [
        {
          fieldName: finalData.IntakeDate
            ? formatIntakeDate(finalData.IntakeDate)
            : '-',
          displayName: t('APPLICATION_DETAIL.INTAKE'),
        },
        {
          fieldName: duration || '-',
          displayName: t('APPLICATION_DETAIL.DURATION'),
        },
        {
          fieldName:
            finalData?.Location__c ??
            finalData?.OpportunityLineItems?.records?.[0]?.Location__c ??
            '-',
          displayName: t('APPLICATION_DETAIL.LOCATION'),
        },
        {
          fieldName: DateFormat(finalData?.CreatedDate?.split('T')[0]) || '-',
          displayName: t('APPLICATION_DETAIL.CREATED_DATE'),
        },
        {
          fieldName: finalData?.Application_Submitted_Date__c
            ? DateFormat(
                finalData?.Application_Submitted_Date__c?.split('T')[0],
              )
            : '-',
          displayName: t('APPLICATION_DETAIL.SUBMITTED_DATE'),
        },
      ]
    }
    return [
      {
        fieldName: finalData.IntakeDate
          ? formatIntakeDate(finalData.IntakeDate)
          : '-',
        displayName: t('APPLICATION_DETAIL.INTAKE'),
      },
      {
        fieldName: duration || '-',
        displayName: t('APPLICATION_DETAIL.DURATION'),
      },
      {
        fieldName:
          finalData?.Location__c ??
          finalData?.OpportunityLineItems?.records?.[0]?.Location__c ??
          '-',
        displayName: t('APPLICATION_DETAIL.LOCATION'),
      },
      {
        fieldName: DateFormat(finalData?.CreatedDate?.split('T')[0]) || '-',
        displayName: t('APPLICATION_DETAIL.CREATED_DATE'),
      },
      {
        fieldName: finalData?.Application_Submitted_Date__c
          ? DateFormat(finalData?.Application_Submitted_Date__c?.split('T')[0])
          : '-',
        displayName: t('APPLICATION_DETAIL.SUBMITTED_DATE'),
      },
    ]
  }

  const fetchOpportunities = async () => {
    let userProfile = await SecureStore.getItemAsync('userProfile')
    userProfile = JSON.parse(userProfile)

    const data = await getOpportunitiesById({
      opportunityId,
      email: userProfile?.email,
    })
    const finalData = data?.response?.[0]

    let tabDetails = infoTabData(finalData)

    if (excludedDurationBrands.includes(finalData.BusinessUnitFilter__c)) {
      tabDetails = tabDetails.filter(
        (item) => item.displayName !== t('APPLICATION_DETAIL.DURATION'),
      )
    }

    setInformationList(tabDetails)

    return finalData
  }

  const admissionsNotesTabData = [
    {
      displayText: t('APPLICATION_DETAIL.ACTION_REQUIRED'),
    },
    {
      displayText: t('APPLICATION_DETAIL.CONDITIONS'),
    },
  ]

  const { data: opportunities, isFetching: isOpportunitiesFetching } = useQuery(
    {
      queryKey: [`getOpportunitiesById-${opportunityId}`],
      queryFn: fetchOpportunities,
      enabled: isFocused && !!opportunityId,
      initialData: [],
    },
  )

  useEffect(() => {
    refetchtaskDetails()
  }, [props])

  const { data: documentType } = useQuery({
    queryKey: ['getDocumentsType'],
    queryFn: async () => fetchDocumentTypes(),
    initialData: [],
    enabled: isFocused && !!opportunities.BusinessUnitFilter__c,
  })

  const {
    data: taskDetails,
    isFetching: isFetchingActionRequiredItems,
    isLoading: isLoadingActionRequiredItems,
    refetch: refetchtaskDetails,
  } = useQuery({
    queryKey: [`getActionRequiredItems-${opportunityId}`],
    queryFn: async () => {
      const response = await getActionRequiredItemsByOpportunityId(
        opportunityId,
      )

      if (response) {
        const restructuredResponse = await categorizeActionItems(response)
        return restructuredResponse
      } else return []
    },
    enabled: !!opportunityId,
  })

  const getFileTypeImage = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase()
    switch (extension) {
      case 'pdf':
        return PdfImage
      case 'png':
        return PngImage
      case 'jpg':
        return JpgImage
      case 'jpeg':
        return JpegImage
      default:
        return MiscImage
    }
  }
  const fetchDocumentTypes = async () => {
    const documentType = await getDocumentType({
      brandName: opportunities.BusinessUnitFilter__c,
    })
    const documentList = documentType.records.map((document) => ({
      label: document.DocumentType__c,
    }))
    return documentList
  }

  const fetchOpportunityDocument = async () => {
    const documentsResponse = await getDocumentsByOpportunityId({
      opportunityId: opportunities?.Id,
    })
    const documentsFinalData = documentsResponse?.response

    if (documentsFinalData?.length > 0) {
      const convertedData = {}
      const convertedInfo = {}
      const applicationLetter = []
      const applicationDocument = []
      let hasOffer = false
      documentsFinalData.forEach((item) => {
        const documentType = item.DocumentType__c
        if (!convertedData[documentType] && !item?.AdmissionLetter) {
          convertedData[documentType] = []
        }
        if (!convertedInfo[documentType] && item?.AdmissionLetter) {
          convertedInfo[documentType] = []
        }
        if (documentType === 'Application form') {
          applicationDocument.push(item)
        }
        const documentCopyItem = item
        documentCopyItem.placeholderImage = getFileTypeImage(item?.Name)
        if (item?.AdmissionLetter) {
          if (documentCopyItem?.OfferLetter) {
            hasOffer = true
          }
          convertedInfo[documentType].push(documentCopyItem)
          applicationLetter.push(documentCopyItem)
        } else {
          convertedData[documentType].push(documentCopyItem)
        }
      })

      const uploadDocuments = getRecentData({ itemList: applicationLetter })
      const recentApplicationDocuments = getRecentData({
        itemList: applicationDocument,
      })
      setApplicationLetterDocuments({
        documents: convertedInfo,
        hasOffer,
        uploadDocuments,
      })
      setDownloadApplicationData(recentApplicationDocuments)
      setApplicationDocuments(convertedData)
    }
    return documentsFinalData
  }

  const { isFetching: isDocumentFetching } = useQuery({
    queryKey: [`getDocumentsByOpportunityId-${opportunities?.Id}`],
    queryFn: fetchOpportunityDocument,
    enabled: isFocused && !!opportunities?.Id,
    initialData: [],
  })

  const { mutation } = useUploadDocument()

  const handleFileUpload = async (properties) => {
    setIsFileUpload(true)
    await mutation.mutateAsync({
      opportunities,
      ...properties,
    })
    setIsFileUpload(false)
  }

  const toggleDropdown = () => {
    dropDownRef?.current?.measure((_fx, _fy, _w, _h, _px, py) => {
      setDropdownTop(py + 50)
      setDropdownLeft(_px - 10)
      setDropDownWidth(_w)
    })
  }

  const handleMoreIcon = async ({ selectedItem }) => {
    if (selectedItem?.type === 'DownloadApplication') {
      await handleNavigation({
        url: downloadApplicationData?.FilePath,
        fileName: downloadApplicationData?.Name,
        translation: t,
      })
    }
  }

  const handleScrollComment = () => {
    if (childRef.current) {
      childRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  const [tabData, setTabData] = useState([
    {
      displayText: t('APPLICATION_DETAIL.ACTION_REQUIRED'),
      isActive: false,
    },
    {
      displayText: t('APPLICATION_DETAIL.INFORMATION'),
      isActive: true,
    },
    {
      displayText: t('APPLICATION_DETAIL.DOCUMENTS_LETTER'),
      isActive: false,
    },
    {
      displayText: t('APPLICATION_DETAIL.OFFERS'),
      isActive: false,
    },
  ])

  useEffect(() => {
    setTabData([
      {
        displayText: t('APPLICATION_DETAIL.ACTION_REQUIRED'),
        isActive: tabIsActive === 'ACTION_REQUIRED' || false,
      },
      {
        displayText: t('APPLICATION_DETAIL.INFORMATION'),
        isActive: tabIsActive ? tabIsActive === 'INFORMATION' : true,
      },
      {
        displayText: t('APPLICATION_DETAIL.DOCUMENTS_LETTER'),
        isActive: tabIsActive === 'DOCUMENTS_LETTER' || false,
      },
      {
        displayText: t('APPLICATION_DETAIL.OFFERS'),
        isActive: tabIsActive === 'OFFERS' || false,
      },
    ])

    let infoTabDetails = infoTabData(opportunities)

    if (excludedDurationBrands.includes(opportunities.BusinessUnitFilter__c)) {
      infoTabDetails = infoTabDetails.filter(
        (item) => item.displayName !== t('APPLICATION_DETAIL.DURATION'),
      )
    }

    setInformationList(infoTabDetails)
  }, [i18n.language, opportunities, tabIsActive])

  const moreIconDropDownDetails = [
    {
      label: t('DROP_DOWN.DOWNLOAD_APPLICATION'),
      type: 'DownloadApplication',
    },
  ]

  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const handleSubmitAllOpportunityTaskFile = async () => {
    setIsSubmitLoading(true)
    const payload = {
      opportunityId,
      brand: opportunities?.Brand__c,
      businessUnitFilter: opportunities?.BusinessUnitFilter__c,
      email: userDetails?.email,
    }
    const response = await submitAllOpportunityTaskDocuments(payload)

    setIsSubmitLoading(false)

    if (response.ok) {
      await queryClient.setQueryData(
        [`getActionRequiredItems-${opportunityId}`],
        (prevData) => {
          if (
            !prevData ||
            !prevData.ActionRequiredItems ||
            !prevData.ActionRequiredItems.Items
          ) {
            return prevData
          }

          // Update the ActionRequiredItems
          const updatedItems = prevData.ActionRequiredItems.Items.map(
            (task) => ({
              ...task,
              Status: 'Completed',
            }),
          )

          // Update the pendingActionRequiredItems if they exist
          const updatedPendingActionRequiredItems =
            prevData.pendingActionRequiredItems?.map((task) => ({
              ...task,
              Status: 'Completed',
            })) || []

          const updatedOpportunityDetail = {
            ...prevData,
            ActionRequiredItems: {
              ...prevData.ActionRequiredItems,
              Items: updatedItems,
            },
            pendingActionRequiredItems: updatedPendingActionRequiredItems,
          }

          global.showToast(
            `The <${
              taskDetails?.outstandingActionRequiredCount
            }> pending documents for <${getProgrammeName(
              opportunities,
            )}> have been submitted successfully.`,
            {
              type: 'success',
              isCompleted: true,
            },
          )

          return categorizeActionItems(updatedOpportunityDetail)
        },
      )

      await queryClient.setQueryData(
        ['getTotalActionRequiredCount'],
        (prevData) =>
          taskDetails.pendingActionRequiredItems.length
            ? prevData - taskDetails.pendingActionRequiredItems.length
            : prevData,
      )
    } else {
      global.showToast(
        `Unable to upload outstanding documents for <${getProgrammeName(
          opportunities,
        )}> at this time. Please try again later.`,
        { type: 'error' },
      )
    }
  }

  const handleUpdateComment = async (data, queryClient, isMarkasRead) => {
    if (!isMarkasRead) {
      data.ids = []
      if (data?.id) {
        data.isRead = true
        data.ids = [data.id]
        delete data.content, delete data.date, delete data.title, delete data.id
      }
    }

    await updateReadState(data)

    await queryClient.setQueryData(
      [`getOpportunitiesById-${data.whatId}`],
      (prevData) => {
        if (prevData && prevData.commentsObj) {
          return {
            ...prevData,
            commentsObj: updateCommentsObj(
              prevData.commentsObj,
              data.ids.map((id) => ({ id, isRead: true })),
            ),
          }
        }
        return prevData
      },
    )
  }

  const updateCommentsObj = (prevCommentsObj, updatedComments) => {
    return prevCommentsObj.map((comment) => {
      const updatedComment = updatedComments.find((c) => c.id === comment.id)
      if (updatedComment) {
        return {
          ...comment,
          isRead: true,
        }
      }
      return comment
    })
  }

  const [selectedOpportunity, setSelectedOpportunity] = useState({})

  const handleSelectedOpportunity = (opportunityDetail) => {
    setModalVisible(true)
    setSelectedOpportunity(opportunityDetail)
  }

  const viewProps = {
    canShowMenu: !!downloadApplicationData?.FilePath,
    brandLogoUrl,
    admissionsNotesTabData,
    applicationDocuments,
    applicationLetterDocuments,
    documentType,
    dropdownLeft,
    dropDownRef,
    dropdownTop,
    dropdownWidth,
    informationList,
    isDocumentFetching,
    isFileUpload,
    handleUpdateComment,
    isLoading: isOpportunitiesFetching || isLoadingActionRequiredItems,
    modalVisible,
    moreIconDropDownDetails,
    opportunities,
    tabData,
    setModalVisible,
    setTabData,
    handleFileUpload,
    handleMoreIcon,
    toggleDropdown,
    handleScrollComment,
    scrollViewRef,
    childRef,
    isActiveComment,
    taskDetails,
    handleSubmitAllOpportunityTaskFile,
    handleSelectedOpportunity,
    selectedOpportunity,
    setSelectedOpportunity,
    isSubmitLoading,
    canExpandCompletedAction,
    navigationText: !isFromDashboard
      ? t('APPLICATION_DETAIL.BACK_TO_APPLICATIONS')
      : t('APPLICATION_DETAIL.BACK_TO_DASHBOARD'),
  }

  return (
    <Suspense fallback={<Text>Loading</Text>}>
      <LayoutView {...viewProps} />
    </Suspense>
  )
}

export default ViewApplicationDetails
