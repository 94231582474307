import React from 'react'
import {
  StyleSheet,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from 'react-native'
import { ScrollView } from 'react-native-web'
import { useNavigation } from '@react-navigation/native'
import LinearGradient from 'react-native-linear-gradient'
import { Icon } from '@app-hero/native-icons'
import { useTheme } from '@libs/theme'
import { useAtom } from 'jotai'
import { AuthLoader, Text } from '@libs/components'
import { AuthLeftContainer } from '../../components'
import { loadingMessage } from '../../constants'
import {
  OTPVerification,
  brandDetailsAtom,
  errorMessageAtom,
  signUpDetailsAtom,
} from '../../utils/atom'

const DesktopView = ({
  dropdownLeft,
  dropdownTop,
  dropdownWidth,
  handleLanguageChange,
  renderModule,
  showVideo,
  toggleDropdown,
  userSelectedLanguage,
  routeName,
  isLoading,
  brandLogoUrl,
  ...props
}) => {
  const { colors } = useTheme()
  const navigation = useNavigation()
  const [brandDetails] = useAtom(brandDetailsAtom)
  const [showOTPVerification, setshowOTPVerification] = useAtom(OTPVerification)
  const [, setError] = useAtom(errorMessageAtom)
  const { width } = useWindowDimensions()
  const [, setSignupDetails] = useAtom(signUpDetailsAtom)

  const handleDeviceWidth = () => {
    if (routeName === 'privacy-policy') return '97%'
    if (routeName === 'sign-up') return '90%'
    if (window.devicePixelRatio === 2) return '70%'
    return '100%'
  }

  if (isLoading)
    return (
      <AuthLoader
        label={loadingMessage?.[routeName]?.label}
        subText={loadingMessage?.[routeName]?.subText}
        brandDetails={brandDetails}
        brandLogoUrl={brandLogoUrl}
      />
    )

  return (
    <View style={styles.container(width)}>
      <View style={styles.leftContainer}>
        <ScrollView showsVerticalScrollIndicator={false}>
          <LinearGradient
            colors={[
              brandDetails?.brand ? colors.white : colors.teritiary,
              brandDetails?.brand ? colors.white : colors.teritiary,
            ]}
            style={styles.mainContainer}
            start={{ x: 0.2, y: 0.8 }}
            end={{ x: 1, y: 0 }}
          >
            <AuthLeftContainer brandLogoUrl={brandLogoUrl} />
          </LinearGradient>
        </ScrollView>
      </View>
      <View
        style={[
          styles.rightContainer,
          { backgroundColor: showVideo ? 'rgba(0,0,0,0.2)' : '' },
        ]}
      >
        <ScrollView
          style={{
            flex: 1,
            backgroundColor: showVideo ? 'rgba(62, 137, 219, 0.3)' : '',
            borderRadius: 20,
          }}
          contentContainerStyle={{ marginTop: 20, marginBottom: 20 }}
        >
          {routeName === 'privacy-policy' && (
            <View
              style={[
                styles.leftHeader,
                { flexDirection: 'row', alignItems: 'center', gap: 6 },
              ]}
            >
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate('sign-up')
                }}
              >
                <Icon name="ArrowNarrowLeft" color={colors.neutral} />
              </TouchableOpacity>
              <Text variant="heading6" color={colors.titleColor}>
                <span style={{ fontWeight: 'normal' }}>Signup/ </span>Privacy
                policy
              </Text>
            </View>
          )}
          {routeName !== 'sign-up' &&
            routeName !== 'privacy-policy' &&
            routeName !== 'login' &&
            routeName !== 'otp' && (
              <View style={styles.leftHeader}>
                <TouchableOpacity
                  onPress={() => {
                    setError('')
                    if (showOTPVerification) {
                      setshowOTPVerification(false)
                      return
                    }
                    if (navigation.canGoBack()) {
                      navigation.goBack()
                    } else navigation.navigate('login')
                  }}
                >
                  <Icon name="ArrowNarrowLeft" color={colors.neutral} />
                </TouchableOpacity>
              </View>
            )}
          {routeName === 'set-password' && (
            <View style={styles.leftHeader}>
              <TouchableOpacity
                onPress={() => {
                  setError('')
                  setSignupDetails((prevDetails) => ({
                    ...prevDetails,
                    password: '',
                    repeatPassword: '',
                  }))
                  if (showOTPVerification) {
                    setshowOTPVerification(false)
                    return
                  }
                  if (navigation.canGoBack()) {
                    navigation.goBack()
                  } else navigation.navigate('login')
                }}
              >
                <Icon name="ArrowNarrowLeft" color={colors.neutral} />
              </TouchableOpacity>
            </View>
          )}

          <View
            style={[
              {
                flex: 1,
                overflow: 'hidden',
                alignSelf: 'center',
                width: handleDeviceWidth(),
              },
            ]}
          >
            {renderModule(props)}
          </View>
        </ScrollView>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: () => ({
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#F4F5FB',
  }),
  leftContainer: { flex: 1, margin: '2rem' },
  mainContainer: {
    borderRadius: 16,
    height: window.innerWidth < 1280 ? '85vh' : '90vh',
  },
  rightContainer: {
    flex: 1,
    flexDirection: 'column',
    paddingTop: 20,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingHorizontal: 30,
    height: 24,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  leftHeader: {
    position: 'absolute',
    zIndex: 20,
    top: 0,
    left: 10,
  },
})

export default DesktopView
