import {
  Platform,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from 'react-native'
import React, { useRef } from 'react'
import { Icon } from '@app-hero/native-icons'
import { useTranslation } from 'react-i18next'
import { Button, Link, Text } from '@libs/components'
import { useNavigation } from '@react-navigation/native'
import { useTheme } from '@libs/theme'
import { isWeb } from '@libs/utils/src/screenLayout'
import { gusUrl, privacyPolicyUrl } from '../../config'
import { handleNavigation } from '../../utils/function'

const PrivacyPolicy = () => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const navigation = useNavigation()
  const childRef = useRef(null)
  const { width } = useWindowDimensions()
  const isDesktop = isWeb(width)

  return (
    <ScrollView
      style={{ flex: 1 }}
      contentContainerStyle={{
        flexDirection: 'column',
        alignItems: isDesktop && 'center',
        justifyContent: 'center',
        marginTop: !isDesktop ? '6vh' : '6vh',
        paddingLeft: isDesktop ? '16%' : '10%',
        paddingRight: isDesktop ? '16%' : '10%',
      }}
      showsVerticalScrollIndicator={false}
      ref={childRef}
    >
      {!isDesktop ? (
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            gap: 16,
            marginBottom: 8,
          }}
        >
          <TouchableOpacity
            onPress={() => {
              navigation.navigate('sign-up')
            }}
          >
            <Icon
              name="ArrowNarrowLeft"
              height={32}
              width={32}
              color={colors.black}
            />
          </TouchableOpacity>
          <Text
            color={colors.cardTitleColor}
            variant="display4"
            style={{
              fontSize: 24,
              fontWeight: 700,
              letterSpacing: 0.2,
              textAlign: isDesktop ? 'center' : 'left',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {t('AUTH.PRIVACY_POLICY')}
          </Text>
        </View>
      ) : (
        <Text
          color={colors.cardTitleColor}
          variant="display4"
          style={{
            marginTop: window.devicePixelRatio === 2 ? 15 : '2%',
            marginBottom: 22,
            fontSize: 24,
            fontWeight: 700,
            letterSpacing: 0.2,
            textAlign: isDesktop ? 'center' : 'left',
          }}
        >
          {t('AUTH.PRIVACY_POLICY')}
        </Text>
      )}

      <View style={{ minHeight: '70vh' }}>
        <View>
          <Text
            variant="display4"
            color={colors.cardTitleColor}
            style={{
              fontWeight: 700,
              marginTop: 10,
              marginBottom: 4,
              lineHeight: 22.3,
            }}
          >
            {t('AUTH.PRIVACY_POLICY_AGREEMENT')}
          </Text>
          <Text
            variant="display4"
            color={colors.authPrimary}
            style={{ lineHeight: 20 }}
          >
            {t('PRIVACY_POLICY.FIRST_PARAGRAPH_1')}{' '}
            <LinkText
              text={t('PRIVACY_POLICY.FIRST_PARAGRAPH_LINK_TEXT')}
              onClick={() =>
                handleNavigation({ url: gusUrl, canValidate: false })
              }
            />
            {t('PRIVACY_POLICY.FIRST_PARAGRAPH_2')}
          </Text>
          <Text
            variant="display4"
            color={colors.authPrimary}
            style={{ marginBottom: 25, lineHeight: 20 }}
          >
            {t('PRIVACY_POLICY.SECOND_PARAGRAPH_1')}{' '}
            <LinkText
              text={t('PRIVACY_POLICY.SECOND_PARAGRAPH_LINK_TEXT')}
              onClick={() =>
                handleNavigation({ url: privacyPolicyUrl, canValidate: false })
              }
            />
            {t('PRIVACY_POLICY.SECOND_PARAGRAPH_2')}
          </Text>
        </View>

        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            alignSelf: 'center',
            marginVertical: 12,
            width: '100%',
          }}
        >
          <Button
            label={t('AUTH.TAKE_ME_BACK')}
            buttonColor={colors.primary}
            labelColors={colors.white}
            buttonStyle={{ width: '100%', borderRadius: 4, height: 56 }}
            labelStyle={{
              fontWeight: '700',
              color: colors.white,
              letterSpacing: 0.2,
            }}
            onPress={() => navigation.navigate('sign-up')}
          />
        </View>
      </View>

      <View style={styles.footer}>
        <Text variant="display4">{t('LINK.HAVE_ACCOUNT')} </Text>
        <Link
          label={t('BUTTON.SIGNIN')}
          labelVariant="display4"
          labelColor={colors.primary}
          handlePress={() => navigation.navigate('login')}
          labelStyle={{ fontWeight: 700 }}
        />
      </View>
    </ScrollView>
  )
}

const LinkText = ({ text = '', onClick = () => {} }) => {
  const { colors } = useTheme()
  return (
    <TouchableOpacity onPress={() => onClick()}>
      <Text
        variant="display4"
        color={colors.primary}
        style={{ textDecoration: 'underline' }}
      >
        {text}
      </Text>
    </TouchableOpacity>
  )
}

export default PrivacyPolicy

const styles = StyleSheet.create({
  footer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
})
